// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-act-tsx": () => import("./../../../src/pages/act.tsx" /* webpackChunkName: "component---src-pages-act-tsx" */),
  "component---src-pages-adopt-form-tsx": () => import("./../../../src/pages/adopt/form.tsx" /* webpackChunkName: "component---src-pages-adopt-form-tsx" */),
  "component---src-pages-adopt-thanks-tsx": () => import("./../../../src/pages/adopt/thanks.tsx" /* webpackChunkName: "component---src-pages-adopt-thanks-tsx" */),
  "component---src-pages-adopt-tsx": () => import("./../../../src/pages/adopt.tsx" /* webpackChunkName: "component---src-pages-adopt-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-write-tsx": () => import("./../../../src/pages/write.tsx" /* webpackChunkName: "component---src-pages-write-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

